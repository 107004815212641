import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import {
  FETCH_PAYMENT_DISCOUNTS,
  GET_PRICING_PLANS,
} from '../../utils/queries';
import { useEffect, useMemo, useState } from 'react';
import { Competition } from '../../utils/types';
import { greenishColor } from '../../utils/theme';
import Loader from '../Loader';
import { notes } from './utils';
import { useStoreActions } from '../../store';
import usePaymentState from '../../hooks/usePaymentState';
// import FAQs from './FAQs';
import PaymentMethodsPopup from './PaymentMethodsPopup';
import PaymobLogo from '../../assets/paymob.png';

export interface Plan {
  amount: number;
  competition: Competition;
  count: number;
  currency: string;
  description: string;
  extraAmount: number;
  extraAmountDescription: string;
  id: string;
  includes: string;
  name: string;
  per: string;
  isInstallment: boolean;
  planName: string;
  uiOrder: number;
}

const PlanTypes = ['Pro', 'Classic'] as const;
export interface Discount {
  id: string;
  amount: string;
  type: 'Percentage' | 'Fixed';
  paymentPlans: Plan[];
  maxDiscountUsage: number;
}

const PricingPlans = ({
  compId,
  seasonId,
  compName,
  isRegistrationDisabled,
}: {
  compId: string | undefined;
  seasonId: string | undefined;
  compName: string | undefined;
  isRegistrationDisabled: boolean | undefined;
}) => {
  const [planType, setPlanType] = useState<(typeof PlanTypes)[number]>('Pro');
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(undefined);
  const [discountData, setDiscountData] = useState<Discount | undefined>(
    undefined
  );
  const { error, playerInfo } = usePaymentState(
    compName,
    selectedPlan,
    discountData
  );
  const [fetchDiscountCode] = useLazyQuery(FETCH_PAYMENT_DISCOUNTS);
  const { popupsModel: popupsActions } = useStoreActions((state) => state);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discountError, setDiscountError] = useState<string | undefined>(
    undefined
  );
  const [discountCode, setDiscountCode] = useState('');

  const { data, loading } = useQuery(GET_PRICING_PLANS, {
    variables: {
      compId,
      seasonId,
    },
  });

  const plans: Plan[] | null = data?.paymentPlans;
  const plansToRender = useMemo(
    () =>
      plans
        ?.filter((plan) => plan.planName === planType)
        ?.sort((a, b) => a.uiOrder - b.uiOrder),
    [planType, plans]
  );

  useEffect(() => {
    if (plansToRender && plansToRender?.length > 0 && !selectedPlan) {
      setSelectedPlan(plansToRender[0]);
    }
  }, [plans, selectedPlan, planType, plansToRender]);

  const handleOpenPaymentMethodsPopup = () => {
    popupsActions.setShowPlanPopupChecker(true);
  };

  const handleApplyDiscount = async (plan?: Plan) => {
    try {
      if (!playerInfo?.id)
        throw new Error('You need to login first to apply discount code');
      setDiscountData(undefined);
      setDiscountError('');
      const { data } = await fetchDiscountCode({
        variables: { where: { name: { equals: discountCode } } },
      });

      if (!data?.paymentDiscounts?.length) throw new Error('No discount found');
      const foundDiscount = data.paymentDiscounts[0];
      if (foundDiscount.maxDiscountUsage <= 0)
        throw new Error('Discount code has been used up');
      const { paymentPlans } = foundDiscount;
      const selPlan = plan || selectedPlan;
      const foundPlan = paymentPlans.find(
        (plan: Plan) => plan.id === selPlan?.id
      );
      if (!foundPlan)
        throw new Error('Discount code is not applicable to the selected plan');
      setDiscountData(data.paymentDiscounts[0]);
    } catch (error) {
      setDiscountError(error.message);
      setDiscountData(undefined);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box p="15px">
      {isRegistrationDisabled ? (
        <Center>
          Registration is disabled or payment is not open for the selected
          league, please choose another league that is opened for registration
        </Center>
      ) : plans && !plans.length && !loading ? (
        <Center>
          No payment plans for the current seleced league, please choose another
          league that is opened for registration
        </Center>
      ) : !plans || loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Box>
          <PaymentMethodsPopup
            compName={compName}
            selectedPlan={selectedPlan}
            discountData={discountData}
          />
          <Box maxW={'650px'} margin={'0px auto'} p="15px">
            <Heading as="h3" size="lg" textAlign="center" mb="20px">
              Choose your payment
            </Heading>
            {/* <Text textAlign="center" fontSize="lg" mb="80px">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            </Text> */}

            <Flex
              gap="3"
              alignItems="center"
              justifyContent={'center'}
              mb="20px"
            >
              {PlanTypes.map((type) => (
                <Button
                  minW={'120px'}
                  onClick={() => {
                    setPlanType(type);
                    setSelectedPlan(undefined);
                  }}
                  rounded="full"
                  colorScheme={type === planType ? 'teal' : 'gray'}
                >
                  {type}
                </Button>
              ))}
            </Flex>

            <Stack
              flexDir={['column', 'column', 'row', 'row']}
              w="full"
              gap="5"
            >
              <Box flex="1">
                {plansToRender?.map((plan, index) => {
                  return (
                    <Box
                      as="button"
                      w="100%"
                      key={plan.id}
                      borderRadius="10px"
                      border="1px solid"
                      borderColor={'gray.200'}
                      cursor={'pointer'}
                      bg={
                        plan.id === selectedPlan?.id ? greenishColor : 'white'
                      }
                      color={plan.id === selectedPlan?.id ? 'white' : 'black'}
                      onClick={() => {
                        setSelectedPlan(plan);
                        if (discountCode) {
                          handleApplyDiscount(plan);
                        }
                      }}
                      mb={plans.length === index + 1 ? '0' : '15px'}
                    >
                      <Stack
                        flexDir={['column', 'column', 'row', 'row']}
                        justifyContent="space-between"
                        alignItems="center"
                        p="10px 20px"
                      >
                        <Box>
                          <Flex alignItems="center" gap="5">
                            {plan.id === selectedPlan?.id ? (
                              <FaCheckCircle size="24" />
                            ) : (
                              <FaCircle
                                size="24"
                                strokeWidth={25}
                                stroke="#dadada"
                                fillOpacity={0}
                              />
                            )}

                            <Box
                              fontSize="lg"
                              textAlign={'left'}
                              display={{ base: 'none', md: 'block' }}
                            >
                              <Box>{plan?.name}</Box>
                            </Box>
                          </Flex>
                        </Box>
                        <Box fontSize="2xl" fontWeight="bold">
                          <Box
                            textAlign={'center'}
                            fontSize="sm"
                            display={{ base: 'block', md: 'none' }}
                          >
                            <Box>{plan?.name}</Box>
                          </Box>
                          <Text as="span" fontSize="lg">
                            {plan?.currency}
                          </Text>{' '}
                          {plan.amount}{' '}
                          <Text
                            as="span"
                            fontSize="lg"
                            color={
                              plan.id === selectedPlan?.id
                                ? 'white'
                                : 'gray.500'
                            }
                          >
                            /{plan.per}
                          </Text>
                          {plan.extraAmountDescription ? (
                            <Box>
                              <Badge
                                bg={'teal.400'}
                                rounded="full"
                                color="white"
                              >
                                <Text as="span">+ {plan?.currency}</Text>{' '}
                                <Text as="span">{plan?.extraAmount}</Text>{' '}
                                <Text as="span">
                                  {plan?.extraAmountDescription}
                                </Text>
                              </Badge>
                            </Box>
                          ) : null}
                        </Box>
                      </Stack>
                    </Box>
                  );
                })}
                <InputGroup size="md" mt="20px">
                  <FormControl isInvalid={Boolean(discountError)}>
                    <Input
                      size="lg"
                      pr="4.5rem"
                      type={discountCode}
                      onChange={(e) => {
                        setDiscountCode(e.target.value);
                        setDiscountError('');
                        setDiscountData(undefined);
                      }}
                      placeholder="I have a discount code"
                    />
                    {discountError ? (
                      <FormErrorMessage>{discountError}</FormErrorMessage>
                    ) : null}
                    <InputRightElement
                      width={!discountData ? '10rem' : '14rem'}
                    >
                      {discountData?.type ? (
                        <Badge
                          colorScheme="green"
                          p="5px 12px"
                          rounded="full"
                          mt="7px"
                        >
                          Discount of {discountData?.amount}
                          {discountData?.type === 'Fixed' ? ' EGP' : ' %'}{' '}
                          Applied!
                        </Badge>
                      ) : (
                        <Button
                          h="2.3rem"
                          mt="7px"
                          onClick={() => handleApplyDiscount()}
                          isDisabled={
                            !discountCode ||
                            isSubmitting ||
                            Boolean(discountError)
                          }
                          isLoading={isSubmitting}
                        >
                          Apply Discount
                        </Button>
                      )}
                    </InputRightElement>
                  </FormControl>
                </InputGroup>
              </Box>

              {/* <Box bg="gray.100" borderRadius="10px" flex="1">
                <Stack
                  textAlign="left"
                  flexDir="column"
                  justifyContent={'space-between'}
                  height="100%"
                  padding="40px"
                >
                  <Box>
                    <Heading as="h3" fontSize="2xl">
                      {selectedPlan?.name || 'Team Registration'}
                      <Box
                        height="5px"
                        rounded="full"
                        width="80px"
                        bg={'teal.400'}
                        mt="10px"
                      />
                    </Heading>
                    <Box mt="35px">{selectedPlan?.description || ''}</Box>

                    <Box mt="35px" flex="1">
                      {selectedPlan?.includes && (
                        <List display="flex" flexWrap={'wrap'} gap={'35px'}>
                          {selectedPlan?.includes
                            ?.split(',')
                            .map((item, index) => {
                              return (
                                <ListItem
                                  key={Date.now() + item + index}
                                  display="flex"
                                  alignItems="center"
                                  gap="2"
                                >
                                  <FaCheckCircle size="15" fill={redishColor} />{' '}
                                  {item}
                                </ListItem>
                              );
                            })}
                        </List>
                      )}
                    </Box>
                  </Box>

                  <Box textAlign={'right'} mt="20px">
                    <Box
                      as="a"
                      href="https://paymob.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Text as="span" fontSize="sm">
                        All Payments powered by
                      </Text>
                      <Image src={PaymobLogo} width="100px" ml="auto" />
                    </Box>
                  </Box>
                </Stack>
              </Box> */}
            </Stack>
            <Box
              textAlign="left"
              mt="20px"
              borderRadius="15px"
              bg="gray.50"
              p="20px"
            >
              <List>
                {notes.map((note) => {
                  return (
                    <ListItem
                      color={'red.500'}
                      fontWeight={'medium'}
                      key={note.name}
                    >
                      * {note.content}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Box as="hr" my="25px" />
            <Box textAlign={['center', 'center', 'center', 'center']}>
              <Button
                size="lg"
                bg={greenishColor}
                color={'white'}
                _hover={{ bg: 'teal.400' }}
                onClick={handleOpenPaymentMethodsPopup}
                isDisabled={Boolean(error ?? false)}
              >
                {error ? error : 'Click to Checkout'}
              </Button>
            </Box>

            <Box textAlign={'center'} mt="40px">
              <Box
                as="a"
                href="https://paymob.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Text as="span" fontSize="sm">
                  All Payments powered by
                </Text>
                <Image src={PaymobLogo} width="100px" mx="auto" />
              </Box>
            </Box>
          </Box>
          <Box h="100px" />
          {/* <Box>
            <FAQs />
          </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default PricingPlans;
