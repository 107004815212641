import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { SERVER_URL } from '../utils/constants';
import BlueLogo from '../assets/AL-Blue-Logo.png';
import { mapKeyToName } from '../utils/helper';
interface Props {
  competition: {
    name: string;
  };
  team: {
    id: string;
    name: string;
    mascot: string;
    secondMascot: string;
    logo: string;
    mascotLogo: string;
    teamLogo: {
      url: string;
    } | null;
  };
}

export default function TeamCard({ team, competition }: Props) {
  const IMAGE = useMemo(() => {
    return team && team.id
      ? SERVER_URL +
          (team.teamLogo && team.teamLogo?.url
            ? team.teamLogo.url
            : team.mascotLogo)
      : BlueLogo;
  }, [team]);

  return (
    <Center py={12}>
      <Box
        role={'group'}
        p={6}
        maxW={'330px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'lg'}
        pos={'relative'}
        zIndex={1}
      >
        <Box
          rounded={'lg'}
          mt={-12}
          pos={'relative'}
          height={'230px'}
          _after={{
            transition: 'all .3s ease',
            content: '""',
            w: 'full',
            h: 'full',
            pos: 'absolute',
            top: 5,
            left: 0,
            backgroundImage: `url(${IMAGE})`,
            filter: 'blur(15px)',
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: 'blur(20px)',
            },
          }}
        >
          <Image
            rounded={'lg'}
            height={260}
            width={282}
            objectFit={'cover'}
            src={IMAGE}
            fallbackSrc={BlueLogo}
          />
        </Box>
        <Center>
          <HStack pt={10} spacing="10">
            <Stack align={'center'}>
              <Text
                color={'gray.500'}
                fontSize={'sm'}
                textTransform={'uppercase'}
              >
                Team
              </Text>

              <Heading fontSize={'1xl'} fontFamily={'body'} fontWeight={'600'}>
                {team.name} {team.mascot}
              </Heading>
            </Stack>
            <Stack>
              <Text
                color={'gray.500'}
                fontSize={'sm'}
                textTransform={'uppercase'}
              >
                League
              </Text>
              <Heading fontSize={'1xl'} fontFamily={'body'} fontWeight={'600'}>
                {mapKeyToName(competition?.name)}
              </Heading>
            </Stack>
          </HStack>
        </Center>
      </Box>
    </Center>
  );
}
